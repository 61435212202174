/* -------------------------------- 

File#: _2_features-v3
Title: Features v3
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */

.features-v3__text {
  padding-top: var(--space-xxl);
  padding-bottom: calc(var(--space-xl) * 2);
  background-color: var(--color-contrast-lower);
}

.features-v3__cards {
  margin-top: calc(var(--space-xl) * -1);

  @media screen and (max-width: 768px) {
	margin-top: 0;
	}
}

/* -------------------------------- 

File#: _1_feature
Title: Feature
Descr: Content group containing text + media (img, video, iframe, inline SVG)
Usage: codyhouse.co/license

-------------------------------- */

.feature {

	.container {
		position: relative;


		&:before {
			content: '';
			position: absolute;
			width: 70vw;
			height: 80%;
			background-size: 600px auto;
			background: #ffffff url('/assets/img/watermerk.svg') no-repeat left bottom;
			z-index: -1;
			//left: -100px;
			top: -100px;
			@media screen and (max-width: 768px) {
				display: none;
			}
		
		}

		&.no--btn {
			&:before {
				content: '';
				position: absolute;
				width: 70vw;
				height: 100%;
				background-size: 600px auto;
				background: #f4f4f4 url('/assets/img/watermerk.svg') no-repeat left bottom;
				z-index: -1;
				//left: -100px;
				top: -100px;

				@media screen and (max-width: 768px) {
					display: none;
				}
			}
		}
	}
}

/* -------------------------------- 

File#: _1_feature-v3
Title: Feature v3
Descr: Content group containing text + media element that outsets its container at a specific viewport size
Usage: codyhouse.co/license

-------------------------------- */

.feature-v3 {}

@each $mq in 'sm' 'md' 'lg' { // define the feature-v3--media-outset@{mq} style at different media queries
  .feature-v3--media-outset\@#{$mq} {
    overflow: hidden;
  }

  @include breakpoint(#{$mq}) {
    .feature-v3--media-outset\@#{$mq} .feature-v3__grid {
      align-items: stretch;
    }

    .feature-v3--media-outset\@#{$mq} .feature-v3__media {
      position: relative;
      height: 100%;

      > * {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        max-width: none;
        width: auto;
      }
    }
  }

  // --invert -> invert order of feature items at specific breakpoints
  @include breakpoint(#{$mq}) {
    .feature-v3--media-outset\@#{$mq}.feature-v3--invert\@#{$mq} .feature-v3__grid > *:last-child {
      order: -1;
      
      .feature-v3__media > * {
        left: auto;
        right: 0
      }
    }
  }
}

/* -------------------------------- 

File#: _1_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */

.feature-v4 {
  --feature-text-offset: 65%; // if percentage = higher -> overlapping text takes more space
  position: relative;
  z-index: 1;
}

@each $breakpoint, $value in $breakpoints {
  @include breakpoint(#{$breakpoint}) {
    .feature-v4__text-offset\@#{$breakpoint} {
      width: calc(100% + var(--feature-text-offset));
    }
  
    .feature-v4--invert\@#{$breakpoint} {
      .feature-v4__grid > *:last-child {
        order: -1;
      }
  
      .feature-v4__text-offset\@#{$breakpoint} {
        margin-left: calc(var(--feature-text-offset) * -1);
      }
    }
  }
}

.icon__container {
  background: var(--color-accent-light);
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;


  img {
    display: block;
    margin: auto;
    width: 32px;
  }
}

/* -------------------------------- 

File#: _1_feature-v9
Title: Feature v9
Descr: A feature section with 2 blocks
Usage: codyhouse.co/license

-------------------------------- */

.feature-v9 {
  &:hover .feature-v9__block:not(:hover) .feature-v9__content {
    opacity: 0.5;
  }

  &:hover .feature-v9__block:not(:hover)::after {
    background-color: alpha(var(--color-black), 0.8);
    backdrop-filter: grayscale(100%);
  }
}

.feature-v9__block {
  position: relative;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: var(--space-xl) var(--space-md);
  min-height: 350px;

  &::before, &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: .2s;
  }

  &::before { // gradient under the content
    height: 70%;
    background: linear-gradient(alpha(var(--color-black), 0), alpha(var(--color-black), 0.8));
    mix-blend-mode: multiply;
  }

  &::after { // overlay layer
    height: 100%;
    background-color: alpha(var(--color-black), 0);
  }

  &:hover .feature-v9__content {
    transform: translateY(-10px);
  }

  @include breakpoint(sm) {
    min-height: 450px;
  }

  @include breakpoint(md) {
    min-height: 600px;
  }

  @include breakpoint(lg) {
    min-height: 700px;
  }
}

.feature-v9__content {
  position: relative;
  z-index: 2;
  color: var(--color-white);
  @include fontSmooth;
  margin: auto auto 0;
  transition: .2s;
}

.feature-v9__link {
  color: var(--color-black);
  text-decoration: none;
  display: inline-block;
  padding: var(--space-xs) var(--space-md);
  background-color: alpha(var(--color-white), 0.8);
  border-radius: var(--radius-md);
  backdrop-filter: blur(5px);
  transition: background-color .2s;

  &:hover {
    background-color: alpha(var(--color-white), 1);
  }
}

/* -------------------------------- 

File#: _2_gallery-v2
Title: Gallery v2
Descr: A gallery of card components with an additional text element
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --gallery-v2-offset: 50px; // grid items offset value 
}

.gallery-v2 {
  position: relative;
  z-index: 1;
}

@include breakpoint(sm) {
  .gallery-v2__grid {
    padding-bottom: var(--gallery-v2-offset);
  }

  .gallery-v2__item:nth-child(2n) {
    transform: translateY(var(--gallery-v2-offset));
  }
}

/* -------------------------------- 

File#: _2_feature-v15
Title: Feature v15
Descr: Feature content with text overlapping a background image
Usage: codyhouse.co/license

-------------------------------- */

.feature-v15 {
  position: relative;
  z-index: 1;
}

.feature-v15__content {
  border-radius: var(--radius-md);
  backdrop-filter: saturate(50%) blur(5px);

  @include breakpoint(md) {
    border-radius: 0;
  }
}

/* -------------------------------- 

File#: _1_parallax-image
Title: Parallax Image
Descr: Figure element with images floating in a 3D space
Usage: codyhouse.co/license

-------------------------------- */

.parallax-img {}

.parallax-img__assets {
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  img:not(:first-of-type) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.js {
  .parallax-img {
    overflow: hidden;
    perspective: 4000px;
    opacity: 0; // hide image while it is initialized in JS
  }

  .parallax-img--loaded {
    opacity: 1;
  }

  .parallax-img__assets {
    transform-style: preserve-3d;
    transform: translateZ(0);
    will-change: transform;
  }
}

/* -------------------------------- 

File#: _1_hero
Title: Hero
Descr: A full-width callout section
Usage: codyhouse.co/license

-------------------------------- */

.hero {
  // if bg = img
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

// --overlay-layer
.hero--overlay-layer {
  &::after { // add a layer in between the img/video background and the content
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4) 67%, rgba(255,255,255,0) 100%);

    //ackground-color: alpha(var(--color-bg), 0.68);
    z-index: 1;
  }
}

/* -------------------------------- 

File#: _2_feature-v16
Title: Feature v16
Descr: A gallery of images linking to different pages
Usage: codyhouse.co/license

-------------------------------- */

/* -------------------------------- 

File#: _1_card-v11
Title: Card v11
Descr: Container of information over a background image
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --card-v11-height: 320px; // card height
  --card-v11-btn-height: 50px; // arrow button height

  @include breakpoint(md) {
    --card-v11-height: 480px;
    --card-v11-btn-height: 70px;
  }
}

.card-v11 {
  display: block;
  position: relative;
  text-decoration: none;
  color: inherit;
  overflow: hidden;

  height: var(--card-v11-height);

  background-color: var(--color-contrast-lower);
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    .card-v11__btn {
      background-color: var(--color-bg);
      color: var(--color-contrast-higher);
    }

    .card-v11__icon {
      line:nth-child(1),
      line:nth-child(2) {
        opacity: 1;
        transform: rotate(0deg);
        transition: opacity 0s .2s, transform .2s .2s var(--ease-in-out);
      }

      line:nth-child(3) {
        stroke-dashoffset: 96;
        transition: stroke-dashoffset .2s var(--ease-in-out);
      }
    }
  }
}

.card-v11__box {
  position: absolute;
  bottom: 0;
  right: 0;

  background-color: alpha(var(--color-contrast-higher), 0.95);

  color: var(--color-bg);
  @include fontSmooth;

  @supports (backdrop-filter: blur(10px)) {
    background-color: alpha(var(--color-contrast-higher), 0.7);
    backdrop-filter: blur(10px);
  }
}

.card-v11__btn {
  display: flex;
  align-items: center;

  height: var(--card-v11-btn-height);

  transition: .4s;
}

.card-v11__icon {
  font-size: 48px; // icon size

  line:nth-child(1),
  line:nth-child(2) {
    transform-origin: 47px 24px;
    opacity: 0;
    transition: opacity 0s .2s, transform .2s var(--ease-in-out);
  }

  line:nth-child(1) {
    transform: rotate(-45deg);
  }

  line:nth-child(2) {
    transform: rotate(45deg);
  }

  line:nth-child(3) {
    stroke-dasharray: 48;
    stroke-dashoffset: 72;
    transition: stroke-dashoffset .2s .2s var(--ease-in-out);
  }
}