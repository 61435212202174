/* -------------------------------- 

File#: _1_card-v8
Title: Card v8
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card-v8 {
  display: block;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--color-bg);
  text-decoration: none;
  transition: 0.2s;

  img {
    display: block;
    width: 100%;
  }

  &:hover {
    box-shadow: var(--shadow-md);

    .card-v8__title {
      background-size: 100% 100%;
    }
  }
}

.card-v8__title {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size 0.3s var(--ease-in-out);
  text-decoration: none;
  background-image: linear-gradient(
    transparent 50%,
    alpha(var(--color-primary), 0.2) 50%
  );
  background-size: 0% 100%;
}

/* -------------------------------- 

File#: _1_card-v3
Title: Card v3
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */

.card-v3 {
  background-color: var(--color-contrast-lower);
}

.card-v3__link {
  text-decoration: none;
  display: block; // fallback
  display: flex;
  flex-direction: column;
  height: 100%;
  color: inherit;
}

.card-v3__img {
  img {
    display: block;
    width: 100%;
  }
}

.card-v3__content {
  padding: var(--space-sm);
}

.card-v3__label {
  font-size: var(--text-sm);
  margin-bottom: var(--space-xs);
  color: var(--color-contrast-medium);
}

.card-v3__footer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm);
  margin-top: auto;

  span {
    // text
    display: inline-block;
    font-size: var(--text-sm);
    will-change: transform;
    transform: translateY(20%);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
  }

  .icon {
    display: block;
    color: inherit;
  }

  &::before {
    // animated bottom line
    content: "";
    width: 100%;
    height: 3px;
    background-color: var(--color-primary);
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: left center;
    transform: scaleX(0);
    transition: transform 0.3s var(--ease-in-out);
  }
}

.card-v3:hover {
  .card-v3__footer {
    span {
      transform: translateY(0);
      opacity: 1;
    }

    &::before {
      transform: scaleX(1);
    }
  }
}

.grid__card {
  position: relative;

  &.grid__card--left {
    margin-left: 100px;
    @media screen and (max-width: 768px) {
      margin-left: var(--offset);
    }
  }

  figure, h3 {
    position: relative;
    z-index: 2;
  }

  .img__container {
    position: absolute;
    width: 514px;
    height: 314px;
    left: -60px;
    top: -100px;
    background-color: alpha(var(--color-primary), 0.1);

    @media screen and (max-width: 768px) {
      left: 0;
      right: 0;
      width: 100%;
      height: 240px;
      top: 0;
    }
  }
}


/* -------------------------------- 

File#: _1_card-v2
Title: Card v2
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */

.card-v2 {
  position: relative;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}

.card-v2__caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(alpha(var(--color-black), 0), alpha(var(--color-black), 1));
  background-blend-mode: multiply;

  color: var(--color-white);
  text-shadow: 0 1px 1px rgba(#000, 0.35);
  @include fontSmooth;
}

// --blurred-img
.card-v2--blur-fx {
  .card-v2__caption {
    background: alpha(var(--color-black), 0.8);
    backdrop-filter: blur(10px);
  }
}

// --link
a.card-v2 {
  display: block;
  text-decoration: none;
  transition: box-shadow .2s;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

/* -------------------------------- 

File#: _1_card-v11
Title: Card v11
Descr: Container of information over a background image
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --card-v11-height: 320px; // card height
  --card-v11-btn-height: 50px; // arrow button height

  @include breakpoint(md) {
    --card-v11-height: 480px;
    --card-v11-btn-height: 70px;
  }
}

.card-v11 {
  display: block;
  position: relative;
  text-decoration: none;
  color: inherit;
  overflow: hidden;

  height: var(--card-v11-height);

  background-color: var(--color-contrast-lower);
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    .card-v11__btn {
      background-color: var(--color-bg);
      color: var(--color-contrast-higher);
    }

    .card-v11__icon {
      line:nth-child(1),
      line:nth-child(2) {
        opacity: 1;
        transform: rotate(0deg);
        transition: opacity 0s .2s, transform .2s .2s var(--ease-in-out);
      }

      line:nth-child(3) {
        stroke-dashoffset: 96;
        transition: stroke-dashoffset .2s var(--ease-in-out);
      }
    }
  }
}

.card-v11__box {
  position: absolute;
  bottom: 0;
  right: 0;

  background-color: alpha(var(--color-contrast-higher), 0.95);

  color: var(--color-bg);
  @include fontSmooth;

  @supports (backdrop-filter: blur(10px)) {
    background-color: alpha(var(--color-contrast-higher), 0.7);
    backdrop-filter: blur(10px);
  }
}

.card-v11__btn {
  display: flex;
  align-items: center;

  height: var(--card-v11-btn-height);

  transition: .4s;
}

.card-v11__icon {
  font-size: 48px; // icon size

  line:nth-child(1),
  line:nth-child(2) {
    transform-origin: 47px 24px;
    opacity: 0;
    transition: opacity 0s .2s, transform .2s var(--ease-in-out);
  }

  line:nth-child(1) {
    transform: rotate(-45deg);
  }

  line:nth-child(2) {
    transform: rotate(45deg);
  }

  line:nth-child(3) {
    stroke-dasharray: 48;
    stroke-dashoffset: 72;
    transition: stroke-dashoffset .2s .2s var(--ease-in-out);
  }
}