:root {
  --btn-font-size: 1em;
  --btn-padding-x: var(--space-lg);
  --btn-padding-y: var(--space-sm);
  --btn-radius: 0;
}

.btn {
  cursor: pointer;
  font-family: var(--font-secondary);
  font-weight: bold;
  text-decoration: none;
  line-height: 1;
  box-shadow: none;
  transition: all 0.3s ease;
 

  &:hover {
    box-shadow: 0px 1px 2px 0 alpha(var(--color-black), 0.12), 0px 1px 1px 0 alpha(var(--color-black), 0.12);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-black), 0.2);
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
}

// themes
.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  @include fontSmooth;

  &:hover {
    background-color: var(--color-primary-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-primary), 0.2);
  }
}

.btn--subtle {
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
  @include fontSmooth;

  &:hover {
    background-color: var(--color-accent-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-accent), 0.2);
  }
}

// feedback
.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

// size
.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}