:root{
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}

.form-control {
  background-color: var(--color-bg);
  border: 2px solid var(--color-contrast-low);

  &::-webkit-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &::-moz-placeholder {
    color: var(--color-contrast-medium);
  }

  &:-ms-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &:-moz-placeholder {
    color: var(--color-contrast-medium);
  }

  &:focus {
    border-color: var(--color-primary);
    box-shadow: 0px 0px 0px 3px alpha(var(--color-primary), 0.2);
    outline: none;
  }
}

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);

  &:focus {
    box-shadow: 0px 0px 0px 3px alpha(var(--color-error), 0.2);
  }
}

.form-legend {
  color: var(--color-contrast-higher);
  font-size: var(--text-md);
  line-height: 1.2;
  margin-bottom: var(--space-xxs);
}

.form-label {
  font-size: var(--text-sm);
}

/* -------------------------------- 

File#: _2_form-template
Title: Form Template
Descr: Form template example
Usage: codyhouse.co/license

-------------------------------- */

.t-form {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-xs);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.t-form__img {
  height: 80px;
  background: url('../../../../../app/assets/img/form-template-img-1.jpg') no-repeat center center;
  background-size: cover;
}

.t-form__content {
  padding: var(--space-md);
}

@include breakpoint(md) {
  @supports (grid-area: auto) {
    .t-form {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }

    .t-form__img {
      height: 100%;
    }
  }
}

/* -------------------------------- 

File#: _1_date-picker
Title: Date Picker
Descr: Input element used to select a date
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --date-picker-trigger-width: 2.5em; // calendar button width
  --date-picker-icon-size: 1.2em; // calendar icon size
  --date-picker-dropdown-gap: 4px; // distance between input and custom dropdown
}

.date-input, .date-input__wrapper {
  position: relative;
}

.date-input__trigger { // calendar button
  @include reset;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: var(--date-picker-trigger-width);
  display: none; // hide button if JS is not enabled

  .icon {
    width: var(--date-picker-icon-size);
    height: var(--date-picker-icon-size);
  }

  &:focus {
    color: var(--color-primary); // icon color
  }
}

.js { // show calendar button if JS is enabled
  .date-input__trigger {
    display: block; // flex fallback
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .date-input__text {
    padding-right: calc(var(--date-picker-trigger-width) + var(--space-xxxs));
  }
}

.date-input--hide-calendar .date-input__trigger { // hide calendar trigger when flexbox is not supported
  display: none;
}

.date-picker {
  display: inline-block;
  position: absolute;
  left: 0;
  top: calc(var(--date-picker-dropdown-gap) + 100%);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  border-radius: var(--radius-md);
  z-index: var(--zindex-popover);
  user-select: none;

  // reset spacing and text units - no longer affected by em units
  @include spaceUnit(1rem);
  @include textUnit(1rem);

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s;
}

.date-picker--is-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity .2s;
}

.date-picker__header {
  background-color: var(--color-contrast-lower);
  padding-top: var(--space-xxs);
}

// month navigation
.date-picker__month { // month wrapper -> name + nav
  position: relative;
  padding: 0 var(--space-xxs);
}

.date-picker__month-label { // month name
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--color-contrast-higher);
  font-size: 1.1em;
}

// CSS grid layout + fallback (month nav/week/dates)
.date-picker__month-nav,
.date-picker__week,
.date-picker__dates {
  @include gridFallback(0);
  min-width: 14em;
  display: grid;
  grid-template-columns: repeat(7, minmax(2em, 2.5em)); // set same grid for days/dates/month nav
  grid-gap: var(--space-xxxs);

  li {
    @include column(14.28%);
  }
}

.date-picker__month-nav {
  justify-content: space-between; // grid fallback

  li {
    position: relative;
  }

  li:last-child {
    grid-column: 7 / 8;
  }
}

.date-picker__month-nav-btn {
  @include reset;
  width: 100%;
  height: 32px;
  border-radius: var(--radius-md);
  transition: .2s;
  color: var(--color-contrast-medium); // icon color

  .icon {
    display: block;
    margin: auto;
  }

  &:hover {
    background-color: var(--color-contrast-low);
    color: var(--color-contrast-high);
  }

  &:focus {
    outline: none;
    background-color: var(--color-contrast-high);
    color: var(--color-bg);
  }

  &:active {
    background-color: alpha(var(--color-contrast-high), 0.9);
  }
}

.date-picker__week { // days of the week list
  padding: 0 var(--space-xxs);
}

.date-picker__day { // day of the week
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875em;
  color: var(--color-contrast-medium);
}

.date-picker__dates { // list of dates
  padding: var(--space-xxs);
}

.date-picker__date { // date button
  @include reset;
  width: 100%;
  height: 32px;
  text-align: center;
  border-radius: var(--radius-md);
  transition: .2s;

  &:hover {
    background-color: var(--color-contrast-lower);
  }

  &[tabindex="0"] {
    color: var(--color-primary);
  }

  &:focus {
    outline: none;
    background-color: alpha(var(--color-primary), 0.15);
    color: var(--color-contrast-higher);
  }

  &.date-picker__date--selected {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}

@supports (grid-area: auto) {
  .date-picker__week li,
  .date-picker__dates li,
  .date-picker__month-nav li:last-child {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  .date-picker__day,
  .date-picker__date,
  .date-picker__month-nav-btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.date-picker__date::-moz-focus-inner { // remove Firefox dotted-outline focus effect
  border: none;
}

/* -------------------------------- 

File#: _2_contact
Title: Contact
Descr: Contact block w/ info about how to get in touch
Usage: codyhouse.co/license

-------------------------------- */

.contact {
  position: relative;
  z-index: 1;
  
  .details-list {
    margin-bottom: var(--space-lg);
  }
}

@include breakpoint(md) {
  @supports (grid-area: auto) {
    .contact {
      .details-list {
        margin-bottom: 0;
      }

      .google-maps {
        height: auto; // = height of .details-list
        padding-bottom: 0;
      }
    }

    .contact__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--space-lg);
    }
  }
}

/* -------------------------------- 

File#: _1_google-maps
Title: Google Maps
Descr: Google Maps component
Usage: codyhouse.co/license

-------------------------------- */

.google-maps {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; // set 16:9 aspect ratio
}

.google-maps--ratio-4\:1 {
  padding-bottom: 25%;
}

.google-maps--ratio-3\:1 {
  padding-bottom: 33%;
}

/* -------------------------------- 

File#: _1_details-list
Title: Details List
Descr: Pairs of related information displayed in a list
Usage: codyhouse.co/license

-------------------------------- */

.details-list {}

.details-list__item {
  padding: var(--space-md) 0;
  border-bottom: 1px solid var(--color-contrast-low);

  > * {
    margin-bottom: var(--space-xxs); // vertical spacing
  }

  dd:last-of-type {
    margin-bottom: 0;
  }
}

.details-list__dt {
  font-weight: bold;
}

.details-list__dd { 
  line-height: 1.4;
}

.details-list__dd--cta {
  margin-top: var(--space-lg);
}

@include breakpoint(md) {
  @supports (grid-area: auto) {
    .details-list__item {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      > * {
        margin-bottom: 0;
      }
    }

    dd:last-of-type {
      text-align: right;
    }

    .details-list__dd--cta {
      margin-top: 0;
    }
  }
}

// --col-3
@include breakpoint(md) {
  @supports (grid-area: auto) {
    .details-list--col-3 {
      .details-list__item {
        display: grid;
        justify-content: start;
        grid-template-columns: 30% 50% 20%;
      }
    }
  }
}